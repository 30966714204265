import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Gatsby from "../images/gatsby.png"
import Elastic from "../images/elasticsearch.png"
import PowerApps from "../images/powerapps.png"
import Material from "../images/material.png"
import Azure from "../images/azure.png"
import Bulma from "../images/bulma.png"
import Sanity from "../images/sanity.jpg"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Services" />
    <HeroMini title="Services"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  {/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">HTML</p>
  </div>
  </div> */}

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">Office 365</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/wired/64/000000/office-365.png"/>
    <p>Build long-term, value-added services for Office 365. Increase productivity, availability, 
    security and mobility. make the migration to Office 365 simple and painless.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">SharePoint</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/wired/64/000000/ms-share-point.png"/>
    <p>Custom SharePoint solutions tailored to your industry standards and unique business needs from 
        Microsoft-certified SharePoint consultants.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">React Web Development</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/wired/64/000000/react.png"/>
    <p>Latest technology solutions and support, as well as custom build websites from scratch 
    with your company in mind.</p>
  </div>
</div>
{/* 
<div className="columns">
  <div className="column content">
  <p className="is-size-2">React</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">CMS</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/pastel-glyph/64/000000/pen-1.png"/>
    <p>Choosing and implementing the right CMS platform for your growing business.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Web Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-glyphs/60/000000/today-apps.png"/>
    <p>Delivers the complete solution from strategy to UX. Creative to product development to rapid 
    builds, helping to achieve your digital KPIs.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">API development</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/api.png"/>
    <p>Allow your organizations to manage and monitor the APIs, ensure the needs of 
    developers and applications.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">CSS</p>
  </div>
  </div>
 */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Gatsby</p>
    <img alt="" style={{maxWidth:"50px"}} src={Gatsby}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Elastic Search</p>
    <img alt="" style={{maxWidth:"50px"}} src={Elastic}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Word Press</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/wordpress.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">CMS</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Power Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src={PowerApps}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sharepoint Search</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios/50/000000/search-bar.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Material UI</p>
    <img alt="" style={{maxWidth:"50px"}} src={Material}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">Azure</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Azure Web Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src={Azure}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Azure Functions</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/lambda.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">React Native</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/react-native.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">Apps</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Bulma</p>
    <img alt="" style={{maxWidth:"50px"}} src={Bulma}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sass</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/sass.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">HTML / CSS</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-filled/50/000000/css.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">Sharepoint</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Azure Cognitive Search</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/pastel-glyph/64/000000/search--v2.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sanity CMS</p>
    <img alt="" style={{maxWidth:"50px"}} src={Sanity}/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">BootStrap</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/windows/64/000000/bootstrap.png"/>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua.</p>
  </div>
</div>

</div>
</main>
  </Layout>
)

export default ServicesPage
